import axios from '@/utils/http'
// -----------------票务中心------------

// 开票设置-开票信息
export const add = () => {
    return axios.request({
        url: '/merchant/merchant-invoice/info',
        data: "",
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

//地址信息
export const addressInfo = () => {
    return axios.request({
        url: '/merchant/merchant-invoice/addressInfo',
        data: "",
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//保存地址信息
export const saveAddressInfo = (valuedata) => {
    return axios.request({
        url: '/merchant/merchant-invoice/saveAddressInfo',
        data: valuedata,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//保存开票设置的基本信息
export const saveInfo = (valuedata) => {
    return axios.request({
        url: '/merchant/merchant-invoice/saveInfo',
        data: valuedata,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据字典类型查询字典下拉框
export const getDownBoxByType = (type) => {
    return axios.request({
        url: '/merchantTask/getDownBoxByType/'+type,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//保存开票设置的基本信息
export const list = (valuedata) => {
    return axios.request({
        url: '/merchant/merchant-invoice/list',
        data: valuedata,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//申请开票--本次申请发票数据
export const applyList = (valuedata) => {
    return axios.request({
        url: '/merchant/merchant-invoice/applyList',
        data: valuedata,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//申请开票
export const apply = (valuedata) => {
    return axios.request({
        url: '/merchant/merchant-invoice/apply',
        data: valuedata,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//各个状态的票数据的总金额
export const index = () => {
    return axios.request({
        url: '/merchant/merchant-invoice/index',
        data: '',
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//删除地址
export const delAddressInfo = (valuedata) => {
    return axios.request({
        url: '/merchant/merchant-invoice/delAddressInfo',
        params: valuedata,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}