<template>
  <div>
    <el-row :gutter="20" class="boxtop">
      <el-col :span="5">
        <el-card class="box-card1">
          <p class="textp"><i class="iconfont icon-fapiaoguanli color"></i></p>
          <div class="textp textpp">
            <p>待申请</p>
            <p>{{mount[0].amount}} 元</p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
         <el-card class="box-card1">
          <p class="textp"><i class="iconfont icon-fapiaoshenhe color"></i></p>
          <div class="textp textpp">
            <p>开票中</p>
            <p>{{mount[1].amount}} 元</p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
         <el-card class="box-card1">
          <p class="textp"><i class="iconfont icon-querenfapiao color"></i></p>
          <div class="textp textpp">
            <p>待邮寄</p>
            <p>{{mount[2].amount}} 元</p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
         <el-card class="box-card1">
          <p class="textp"><i class="iconfont icon-fapiaojisongguanli color"></i></p>
          <div class="textp textpp">
            <p>已邮寄</p>
            <p>{{mount[3].amount}} 元</p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
         <el-card class="box-card1">
          <p class="textp"><i class="iconfont icon-fapiaozuofei color"></i></p>
          <div class="textp textpp">
            <p>开票失败</p>
            <p>{{mount[4].amount}} 元</p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="box-card " style="margin-top:10px">
      <div slot="header" class="clearfix">
      <p style=" font-size: 16PX;">开票记录</p>
      <p class="textsize">1.发票默认为每月最后一天统一邮寄，快递默认顺丰。</p>
      <p class="textsize">2.如需加急处理单一一张发票，请点击邮寄到付，工作人员会在一个工作日内安排寄出，邮寄到付产生的快递费需要自行承担。</p>
      <p class="textsize">3.当月最后一天14:00之后申请开票，次月开具、次月寄出。</p>
      <span style=" font-size: 14PX;margin-top:20px;display:inline-block">筛选条件</span>
      </div>
      <div>
        <div class="text">
          任务名称
          <el-input v-model="taskName" style="width:200px"></el-input>
        </div>
        <div class="text">
          订单号
          <el-input v-model="batchNo"  style="width:200px"></el-input>
        </div>
           <div  class="text">
              开票状态
             <el-select v-model="merchantInvoiceState" placeholder="请选择" style="width:160px" clearable>
                <el-option
                    v-for="item in tranList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
             </el-select> 
           </div>
            <div  class="text">
              邮寄方式
             <el-select v-model="sendType" placeholder="请选择" style="width:160px" clearable>
                <el-option
                    v-for="item in tranTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
             </el-select> 
           </div>
            <el-button type="primary" style="margin-left:40px;width:100px" @click="getDataList(1)">查询</el-button>
      </div>
      <div style="margin-top:14px">  <el-button type="primary" style="margin-left:40px;width:100px" :disabled="disabled" @click="paperinvo()">申请开票</el-button></div>
       <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px"
       :row-key="getRowKeys"
       @selection-change="handleSelectionChange">
       <el-table-column
         :selectable="selectable"
          type="selection"
          :reserve-selection="true"
          width="55">
        </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="taskName"
        label="任务名称"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="batchNo"
        label="订单号（批次号）"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="amount"
        width="130"
        label="金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="createdAt"
        label="申请开票时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="stateText"
        label="开票状态">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="invoiceTypeText"
        label="发票类目">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="curApproves"
        label="当前审核人">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="sendTypeText"
        width="130"
        label="邮寄方式">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="fhdm"
        width="130"
        label="发票编号">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="expressNo"
        width="130"
        label="快递单号">
      </el-table-column>
    </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
    </el-card>
  </div>
</template>

<script>
import {getDownBoxByType,list,index} from  "@/api/ticketing.js";
export default {
  data() {
    return {
      tranList:[],
      sendType:"",
      merchantInvoiceState:"",
      tranTypeList:[],
      pageSize: 10,
      totalCount: 0,//数据长度
      currPage: 1,
      batchNo:"",
      taskName:"",
      tableData:[],
      load:false,
      multipleSelection:[],//多选
      disabled: true,
      mount:[{amount:0},{amount:0},{amount:0},{amount:0},{amount:0}]
    };
  },
  mounted() {
    this.up()
    this.getDataList()
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },

    up(){
      let datavalue="MERCHANT_INVOICE_LOG_STATE"
      let datavalue1="MERCHANT_INVOICE_LOG_SEND_TYPE"
      getDownBoxByType(datavalue).then((data=>{
        if (data.code==200) {
          this.tranList =data.data
        }
      }))
      getDownBoxByType(datavalue1).then((data=>{
        if (data.code==200) {
          this.tranTypeList =data.data
        }
      }))
       index().then((data=>{
        if (data.code==200) {
          this.mount =data.data
          console.log( this.mount);
        }
      }))
    },
        // 列表
    getDataList(a){
        this.load=true
       if (a) {  //判断是不是搜索
          this.currPage=1 
          this.pageSize=10
        }
        let datavalue={
           "current": this.currPage,
            "size": this.pageSize,
            "sendType":this.sendType,
            "merchantInvoiceState":this.merchantInvoiceState,
            "taskName":this.taskName,
            "batchNo":this.batchNo
        }
      list(datavalue).then((data=>{
           this.load=false
          this.tableData=data.data.records
          this.totalCount=data.data.total
      }))
    },
     // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
      selectable(row){
      if(row.state == 501020||row.state == 501040||row.state == 501030){ //开票中 已邮寄 待邮寄 禁用
        return false
      }else{
        return true
      }
    },
      // 多选
       handleSelectionChange(evt){
      
        this.multipleSelection=evt
        console.log(evt,"123")
        if (this.multipleSelection=="") {
          this.disabled=true
        }else{
          this.disabled=false
        }
      },
      // 开票
      paperinvo() {
        this.$store.commit("user/updateIdarr",this.multipleSelection);//存储企业信息数组
        this.$router.push({name:"ticketing/record",})
      }
    }
};
</script>

<style  scoped>
 .color{
      color: #9e99b9;
      font-size: 70px;
      font-weight: 400;
  }
  .textp{
    display: inline-block;
     margin-left: 30px;
  }
  .boxtop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: row nowrap;
}
.box-card1{
   height: 152px;
  
}
.textp p{
 font-size: 16PX;
}
.textpp{
  margin-left: 10px;
}
.textsize{
   font-size: 14PX;
}
.text{
    font-size: 13px;
    display: inline-block;
    margin-left: 20px;
}
</style>


